<template>
  <v-container fluid tag="section">
    <v-row justify="space-around">
      <v-col cols="12" md="4">
        <v-card class="pa-4">
          <v-card-title>
            פרטים
          </v-card-title>
          <v-spacer />
          <v-card-text v-if="device">
            <v-row>
              <v-col cols="12" md="12" class="pb-1">
                <strong>מזהה המכשיר:</strong>
              </v-col>
              <v-col cols="12" md="12" class="pt-0 purple--text">
                <strong>{{ device.identifier }}</strong>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('password:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex flex-row justify-space-between align-center">
                  <div class="w-80">
                    <span v-if="!passwordVisibilty">●●●●●●●●</span>
                    <span v-show="passwordVisibilty && password">
                      {{ password }}
                    </span>
                    <v-progress-linear
                      v-show="passwordVisibilty && !password"
                      color="blue"
                      indeterminate
                    />
                  </div>
                  <v-icon
                    v-if="permissions.show_passwords"
                    :color="!passwordVisibilty ? 'blue' : 'red'"
                    size="26"
                    class="clickable"
                    @click="showPassword"
                  >
                    {{ `mdi-eye${passwordVisibilty ? '-off' : ''}` }}
                  </v-icon>
                </div>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" md="12" class="pb-1">
                <strong>{{ $phraser.set('last_communication:') }}</strong>
              </v-col>
              <v-col cols="12" md="12" class="pt-0">
                <strong
                  :class="device.online ? 'success--text' : 'error--text'"
                >
                  {{ formatTime(device.received, 'MMMM Do YYYY, HH:mm:ss') }}
                </strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="pb-1">
                <strong>{{ $phraser.set('updated at:') }}</strong>
              </v-col>
              <v-col cols="12" md="12" class="pt-0">
                <span>{{ formatTime(device.updated_at) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="pb-1">
                <strong>{{ $phraser.set('created at:') }}</strong>
              </v-col>
              <v-col cols="12" md="12" class="pt-0">
                <span>{{ formatTime(device.created_at) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="pa-4">
          <v-card-title>
            המכשיר
          </v-card-title>
          <v-spacer />
          <v-card-text v-if="device">
            <v-row align="center">
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('type:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <span
                  :class="
                    !isNaN(device.service)
                      ? services[device.service].text_color
                      : services[3].text_color
                  "
                >
                  <v-icon
                    :color="
                      !isNaN(device.service)
                        ? services[device.service].color
                        : services[3].color
                    "
                    v-text="
                      !isNaN(device.service)
                        ? services[device.service].icon
                        : services[3].icon
                    "
                  />
                  {{ services[device.service].text }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('role:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <span>{{ $phraser.set(roles[device.settings.role]) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <strong>חיישנים:</strong>
              </v-col>
              <v-col cols="12" md="6">
                <sensors-icons :type="device.settings.sensor_type" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('users:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <span>{{ countUsers }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('broadcast_cycle:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <span>{{ device.settings.cycle }}
                  {{ $phraser.set('time.seconds') }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="device && device.data.signal" cols="12" md="4">
        <v-card class="pa-4">
          <v-card-title>
            קליטה
          </v-card-title>
          <v-spacer />
          <v-card-text>
            <v-row
              v-for="(rssi, gateway) in device.data.signal"
              :key="gateway"
              class="justify-center"
            >
              <v-col cols="4" class="flex-grow-1">
                <strong>{{ gateway }}:</strong>
              </v-col>
              <v-col cols="3" class="flex-shrink-0">
                <strong dir="ltr" :class="getDbmColor(rssi.rssi)">{{ rssi.rssi }} <small>dBm</small></strong>
              </v-col>
              <v-col cols="5" class="flex-grow-1">
                <span>{{ formatTime(rssi.received) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import SensorsIcons from '@/views/dashboard/components/SensorsIcons'
export default {
  name: 'DeviceDetails',
  components: { SensorsIcons },

  data: () => ({
    form: {},
    submitted: false,
    loading: false,
    password: null,
    passwordVisibilty: false,
    services: [
      {
        text: 'BLE',
        icon: 'mdi-bluetooth',
        color: 'light-blue darken-3',
        text_color: 'blue--text text--darken-4',
      },
      {
        text: 'WiFi',
        icon: 'mdi-wifi',
        color: 'blue darken-4',
        text_color: 'light-blue--text text--darken-3',
      },
      {
        text: 'סלולרי',
        icon: 'mdi-signal-cellular-3',
        color: 'cyan darken-3',
        text_color: 'cyan--text text--darken-3',
      },
      { text: 'לא נתמך', icon: '', color: 'red', text_color: 'red' },
    ],
    roles: ['sensor', 'controller'],
  }),

  computed: {
    device () {
      const device = this.$store.getters['devices/getBySlug'](
        this.$route.params.slug,
      )
      return device
    },
    countUsers () {
      return this.$store.getters['usersDevices/countUsersByIdentifier'](
        this.device.identifier,
      )
      // return 0
    },
    now () {
      return this.$store.getters['time/now']
    },
    permissions () {
      return this.$store.getters['user/permissions']
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },

  mounted () {
    if (this.device) {
      // console.log(this.device.data)
      // console.log(this.countUsers)
    }
  },

  methods: {
    getDbmColor (dBm) {
      dBm = parseInt(dBm)
      switch (true) {
        case dBm < -98:
          return 'red--text'
        case dBm < -96:
          return 'deep-orange--text text--darken-3'
        case dBm < -94:
          return 'deep-orange--text text--darken-2'
        case dBm < -92:
          return 'orange--text text--darken-2'
        case dBm < -90:
          return 'amber--text text--darken-2'
        case dBm < -85:
          return 'light-green--text accent-1'
        case dBm < -80:
          return 'light-green--text'
        case dBm < -70:
          return 'green--text'
        default:
          return 'green--text text--darken-2'
      }
    },
    showPassword () {
      if (this.passwordVisibilty) {
        this.passwordVisibilty = false
      } else {
        this.passwordVisibilty = true
        if (!this.password) {
          this.$api
            .get('/devices/' + this.device.slug + '/password')
            .then(res => {
              this.password = res.data.data
            })
            .catch(() => {})
        }
      }
    },
    formatTime (time, format = 'lll') {
      if (!isNaN(time) && String(time).length === 10) time = time * 1000
      return moment(time)._isValid ? moment(time).format(format) : time
    },
  },
}
</script>
