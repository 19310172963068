var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" פרטים ")]),_c('v-spacer'),(_vm.device)?_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("מזהה המכשיר:")])]),_c('v-col',{staticClass:"pt-0 purple--text",attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.device.identifier))])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$phraser.set('last_communication:')))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('strong',{class:_vm.device.online ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.device.received, 'MMMM Do YYYY, HH:mm:ss'))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$phraser.set('updated at:')))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('span',[_vm._v(_vm._s(_vm.formatTime(_vm.device.updated_at)))])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$phraser.set('created at:')))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('span',[_vm._v(_vm._s(_vm.formatTime(_vm.device.created_at)))])])],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" המכשיר ")]),_c('v-spacer'),(_vm.device)?_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v(_vm._s(_vm.$phraser.set('type:')))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{class:!isNaN(_vm.device.service)
                    ? _vm.services[_vm.device.service].text_color
                    : _vm.services[3].text_color},[_c('v-icon',{attrs:{"color":!isNaN(_vm.device.service)
                      ? _vm.services[_vm.device.service].color
                      : _vm.services[3].color},domProps:{"textContent":_vm._s(
                    !isNaN(_vm.device.service)
                      ? _vm.services[_vm.device.service].icon
                      : _vm.services[3].icon
                  )}}),_vm._v(" "+_vm._s(_vm.services[_vm.device.service].text)+" ")],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v(_vm._s(_vm.$phraser.set('role:')))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',[_vm._v(_vm._s(_vm.$phraser.set(_vm.roles[_vm.device.settings.role])))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v("חיישנים:")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('sensors-icons',{attrs:{"type":_vm.device.settings.sensor_type}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v(_vm._s(_vm.$phraser.set('users:')))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',[_vm._v(_vm._s(_vm.countUsers))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v(_vm._s(_vm.$phraser.set('broadcast_cycle:')))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',[_vm._v(_vm._s(_vm.device.settings.cycle)+" "+_vm._s(_vm.$phraser.set('time.seconds')))])])],1)],1):_vm._e()],1)],1),(_vm.device && _vm.device.data.signal)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" קליטה ")]),_c('v-spacer'),_c('v-card-text',_vm._l((_vm.device.data.signal),function(rssi,gateway){return _c('v-row',{key:gateway,staticClass:"justify-center"},[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"4"}},[_c('strong',[_vm._v(_vm._s(gateway)+":")])]),_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":"3"}},[_c('strong',{class:_vm.getDbmColor(rssi.rssi),attrs:{"dir":"ltr"}},[_vm._v(_vm._s(rssi.rssi)+" "),_c('small',[_vm._v("dBm")])])]),_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"5"}},[_c('span',[_vm._v(_vm._s(_vm.formatTime(rssi.received)))])])],1)}),1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }